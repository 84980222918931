import Input from './components/Input'
import './index.css'

function App() {
  return (
    <div className="app">
      <h1 className="header">Playback Speed Calculator</h1>
      <Input />
    </div>
  );
}
// TODO
// Optional: compare to famous books

export default App;
