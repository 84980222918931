import React, { useState } from 'react'
import Result from './Result'

function Input() {
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [speed, setSpeed] = useState(null)

  const handleChange = (e) => {
    const set = e.target.name === 'hours' ? setHours : e.target.name === 'minutes' ? setMinutes : setSpeed
    console.log('wtf is this', set)
    set(e.target.value)
  }
  const seconds = (hours * 3600) + (minutes * 60)

  return (
      <div>
        <div className="input-container" onChange={(e) => handleChange(e)}>
            <div className="input-group">
              <label>Hours</label>
              <input type="number" name="hours" value={hours} />
            </div>
            <div className="input-group">
              <label>Minutes</label>
              <input type="number" name="minutes" value={minutes} />
            </div>

            <div className="input-group">
              <label>Playback Speed</label>
              <input type="number" name="speed" step="0.1" value={speed} />
            </div>
        </div>


        <Result seconds={seconds} playbackSpeed={speed} />
      </div>
  );
}

export default Input;