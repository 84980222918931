import React from 'react'

function Result({ seconds, playbackSpeed }) {
  const newTime = seconds / playbackSpeed
  const hours = Math.floor(newTime / 3600)
  const minutes = Math.floor((newTime % 3600) / 60)

  let result = null

  if (seconds && playbackSpeed) {
    result = `${hours} hours and ${minutes} minutes`
  }
  return (
      <div className="input-container">
        <div>Your new time is: {result}</div>
      </div>
  );
}

export default Result;